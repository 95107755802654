$(document).on("toolbar_setup", ()=>{
    new CheckInOutSwitch()
})

class CheckInOutSwitch {
    constructor() {
        this.fetch_active_employee().then(r=>{
            if (r['name']) {
                this.employee = r['name']
                frappe.run_serially([
                    ()=>this.last_stage(),
                    ()=>this.make()
                ])
            }
        })
    }
    make() {
        this.$wrapper = $(`<li class="switch-lan">
            <label class="switch">
                <input type="checkbox" ${this.status==="IN"? "checked": ""} class="switch-input" id="empCheckInOut">
                <span class="switch-label" data-on="IN" data-off="OUT"></span>
                <span class="switch-handle"></span>
            </label>
        </li>`).appendTo($("header .navbar-collapse.justify-content-end .navbar-nav:last-child"))
        this.$checkbox = this.$wrapper.children("input.switch-input")
        this.$wrapper.on("click",  ".switch-input", ()=>{
            this.make_log()
        })
    }
    make_log() {
        let val = this.$checkbox.prop("checked")?"IN":"OUT"
        frappe.xcall("office_ccl.utils.employee_checkin.make_log").then(r=>{
            if(!r.xhr) {
                this.status = r.log_type
                frappe.show_alert(__("Updated"))
            }else {
                this.status = val ==="IN"?"OUT":"IN"
                frappe.show_alert(__("Sorry! Unable to Entry please contact with System Admin"))
            }
            this.$checkbox.prop({"checked":this.status==="IN"})
        })
    }
    last_stage() {
        return frappe.xcall("office_ccl.utils.employee_checkin.get_last_stage").then(r=>{
            this.status = r
        })
    }
    fetch_active_employee() {
        return frappe.xcall("frappe.client.get_value", {
            doctype: "Employee",
            filters: {
                user_id: frappe.session.user,
                status: "Active"
            },
            fieldname: "name",
            as_dict: 0
        })
    }
}